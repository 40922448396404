<template>
  <div>
    <div class="home-baner home-baner2">
      <div class="home-baner-cont home-baner-cont2">
        <p>
          <span class="color"><b>Tu ADN</b></span> dicta quién eres, qué te
          gusta y
          <span class="background"> qué te mueve.</span>
        </p>
        <div class="home-baner-cont-desc">
          <h2><b>¿Qué es una prueba genómica en cáncer?</b></h2>
          <br>
          <p>
            Las pruebas genéticas analizan una muestra de un tumor maligno para
            calcular el nivel de actividad de ciertos genes. El nivel de
            actividad de estos genes afecta el comportamiento del cáncer,
            incluida la probabilidad que tiene de crecer y propagarse. Las
            pruebas genéticas se usan para ayudar a tomar decisiones sobre si
            sería beneficioso realizar otros tratamientos después de la cirugía.
          </p>
        </div>
      </div>
    </div>
    <div class="portafolio">
      <div class="portafolio-img1">
        <img src="../assets/Assetsx1/Back_Portafolio01.png" />
      </div>
      <div class="portafolio-img2">
        <img src="../assets/Assetsx1/ADN_BackPortafolio.png" />
      </div>
      <div class="portafolio-img3">
        <img src="../assets/Assetsx1/Back_Portafolio01.png" />
      </div>
      <div class="portafolio-title">
        <p>Portafolio<br /><b>de servicios</b></p>
      </div>
      <div class="portafolio-contslideL">
        <div class="portafolio-titleimg">
          <img src="../assets/Assetsx1/Img_diagnosis.png" />
          <p>
            DIAGNOSIS <br />
            <strong>TEST</strong>
          </p>
        </div>
        <carousel
          id="carousel-diagnosis"
          :items="2"
          :margin="175"
          :loop="true"
          :autoplay="true"
          :autoplayHoverPause="true"
          :dots="false"
          :nav="true"
          :navText="[prevDiagnosis, nextDiagnosis]"
          :responsive="{
            0: { margin: 111, nav: true },
            400: { margin: 130, nav: true },
            700: { margin: 175, nav: true },
            2000: { margin: 250, nav: true },
          }"
        >
        <!-- <div class="portafolio-item" v-for="diagnosisItem in diagnosisItems" :key="diagnosisItem">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img :src="diagnosisItem.img" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>{{diagnosisItem.title}}</h1>
                <p>
                  {{diagnosisItem.description}}
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo(diagnosisItem.link)">
                Ver más
              </button>
            </div>
          </div> -->
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/Centogene@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>CentoGene</h1>
                <p>
                  Las pruebas genéticas para los cánceres hereditarios
                  proporcionan resultados que cambian la vida de los pacientes
                  afectados y sus familiares. Habiendo identificado variantes
                  genéticas asociadas con enfermedades oncológicas en más de 200
                  genes diferentes, CentoGene puede proporcionar una gama
                  completa para fomentar el diagnóstico, el pronóstico, la
                  selección del tratamiento y el seguimiento del cáncer.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://www.centogene.com/')">
                Ver más
              </button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/LipidInCode@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>Lipid InCode</h1>
                <p>
                  Lipid inCode® es un test de diagnóstico genético alineado con
                  las recomendaciones de los expertos a nivel internacional, y
                  que analiza los 7 genes más frecuentemente asociados a la
                  hipercolesterolemia familiar. Además, también evalúa otros
                  aspectos importantes para orientar y adecuar el tratamiento de
                  la hipercolesterolemia en cada paciente.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button
                @click="
                  goTo(
                    'https://www.genincode.com/es/lipid-incode-test-genetico-hipercolesterolemia/'
                  )
                "
              >
                Ver más
              </button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/OncoRisk@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>OncoRisk</h1>
                <p>
                  OncoRisk® evalúa el riesgo genético a desarrollar cáncer
                  hereditario de: mama, ovario, útero, colon y recto, melanoma,
                  páncreas, gástrico y próstata. Conocer tu riesgo te permite
                  enfocar estrategias de prevención y diagnóstico temprano.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://genethic.mx/onco_risk/')">Ver más</button>
            </div>
          </div>
        </carousel>
      </div>
      <div class="portafolio-contslideR">
        <carousel
          id="carousel-prognosis"
          :items="2"
          :margin="175"
          :loop="true"
          :autoplay="true"
          :autoplayHoverPause="true"
          :dots="false"
          :nav="true"
          :navText="[prevPrognosis, nextPrognosis]"
          :responsive="{
            0: { margin: 111, nav: true },
            400: { margin: 130, nav: true },
            700: { margin: 175, nav: true },
            2000: { margin: 250, nav: true },
          }"
        >
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/MammaPrint@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>Mammaprint</h1>
                <p>
                  Es la única prueba de perfil de expresión genómica autorizada
                  por la FDA para evaluar el riesgo individual de recurrencia o
                  metástasis en pacientes diagnosticados con cáncer de mama en
                  los estadios I y II.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://genethic.mx/mammaprint/')">Ver más</button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/IMMUNOSCORE@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>Immunoscore</h1>
                <p>
                  Immunoscore aparece como la mejor herramienta para definir los
                  pronósticos de los pacientes con cáncer, identificar mejor a
                  los pacientes con alto riesgo de recidiva tumoral y
                  estratificar a los pacientes que podrían beneficiarse de las
                  terapias adyuvantes.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://io.veracyte.com/')">Ver más</button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/Thyroseq@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>Thyroseq</h1>
                <p>
                  Diagnóstico molecular por perfil genómico exhaustivo de 112
                  genes en nódulos tiroideos con citología indeterminada para la
                  evaluación preoperatoria de nódulos tiroideos con citología
                  indeterminada. Se necesita una muestra de aspirado de aguja
                  fina (FNA).
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://www.thyroseq.com/')">
                Ver más
              </button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/ThromboInCode@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>ThromboInCode</h1>
                <p>
                  Prueba diagnóstica y de tamizaje para los individuos en riesgo
                  de desarrollar eventos trombóticos, estudia 12 variantes
                  genéticas asociadas a la trombofilia.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://www.genincode.com/es/')">
                Ver más
              </button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/EndeavorL@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>Endeavor</h1>
                <p>
                  Es una prueba para cáncer somático. Abarca cualquier tumor
                  sólido y contempla el análisis de todos los genes recomendados
                  por las guías NCCN para tumores sólidos.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('')">Ver más</button>
            </div>
          </div>
        </carousel>
        <div class="portafolio-titleimg portafolio-titleimgR">
          <img src="../assets/Assetsx1/Img_prognosis.png" />
          <p>
            PROGNOSIS <br />
            <strong>TEST</strong>
          </p>
        </div>
      </div>
      <div class="portafolio-contslideL">
        <div class="portafolio-titleimg">
          <img src="../assets/Assetsx1/Img_Wellbeing.png" />
          <p>WELLBEING</p>
        </div>
        <carousel
          id="carousel-wellbeing"
          :items="2"
          :margin="175"
          :loop="true"
          :autoplay="true"
          :autoplayHoverPause="true"
          :dots="false"
          :nav="true"
          :navText="[prevWellbeing, nextWellbeing]"
          :responsive="{
            0: { margin: 111, nav: true },
            400: { margin: 130, nav: true },
            700: { margin: 175, nav: true },
            2000: { margin: 250, nav: true },
          }"
        >
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/NutriGenethic@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>NutriGenethic®</h1>
                <p>
                  Es una prueba genómica que evalúa 115 marcadores genéticos
                  relacionados a la respuesta de tu organismo a los diferentes
                  nutrientes que necesitamos para tener un buen estado de salud
                  y un mejor estilo de vida.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://nutrigenethic.mx/')">Ver más</button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/CardioInCode@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>CardioInCode</h1>
                <p>
                  CardioInCode es una herramienta de evaluación de riesgo de
                  infarto y enfermedades del sistema cardiovascular. Evalúa
                  riesgo genético de diabetes, hipertensión, dislipidemias,
                  trombosis.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('https://www.genincode.com/es/')">
                Ver más
              </button>
            </div>
          </div>
          <div class="portafolio-item">
            <div class="portafolio-item-cont">
              <div class="portafolio-item-cont-img">
                <img src="../assets/Assetsx2/BeSkin@2x.png" />
              </div>
              <hr />
              <div class="portafolio-item-cont-desc">
                <h1>Be Skin</h1>
                <p>
                  El análisis de Be Skin se centra en las consecuencias de las
                  mutaciones genéticas que afectan las características de tu
                  piel y sus necesidades nutricionales. Su objetivo es
                  reconocerlas y ayudarte a aprovecharlas al elegir la dieta y
                  el cuidado de la piel que son ideales para ti.
                </p>
              </div>
            </div>
            <div class="portafolio-item-buttom">
              <button @click="goTo('')">Ver más</button>
            </div>
          </div>
        </carousel>
      </div>

      <div class="footer-servicios">
        <div class="portafolio-img4">
          <img src="../assets/Assetsx1/Back002.png" />
        </div>
        <div class="footer-logos footer-logosS">
          <div>
            <img src="../assets/Assetsx1/cuidarte.png" draggable="false" />
          </div>
          <div>
            <img src="../assets/Assetsx1/Sohin.png" draggable="false" />
          </div>
          <div>
            <img src="../assets/Assetsx1/Concierge.png" draggable="false" />
          </div>
          <div>
            <img
              src="../assets/Assetsx1/HARMONY-HEALTH-VERTICAL.png"
              draggable="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  name: "Servicios",
  components: { carousel },
  data() {
    return {
      prevDiagnosis:
        '<i class="far fa-arrow-alt-circle-left flecha-izquierdaDiagnosis"></i>',
      nextDiagnosis:
        '<i class="far fa-arrow-alt-circle-right flecha-derechaDiagnosis"></i>',
      prevPrognosis:
        '<i class="far fa-arrow-alt-circle-left flecha-izquierdaPrognosis"></i>',
      nextPrognosis:
        '<i class="far fa-arrow-alt-circle-right flecha-derechaPrognosis"></i>',
      prevWellbeing:
        '<i class="far fa-arrow-alt-circle-left flecha-izquierdaWellbeing"></i>',
      nextWellbeing:
        '<i class="far fa-arrow-alt-circle-right flecha-derechaWellbeing"></i>',
      diagnosisItems: [
        {
          img: "../assets/Assetsx1/Centogene.png",
          title: "CentoGene",
          description:
            "Las pruebas genéticas para los cánceres hereditarios proporcionan resultados que cambian la vida de los pacientes afectados y sus familiares. Habiendo identificado variantes genéticas asociadas con enfermedades oncológicas en más de 200 genes diferentes, CentoGene puede proporcionar una gama completa para fomentar el diagnóstico, el pronóstico, la selección del tratamiento y el seguimiento del cáncer.",
          link: "https://www.centogene.com/",
        },
        {
          img: "../assets/Assetsx1/LipidInCode.png",
          title: "Lipid InCode",
          description:
            "Lipid inCode® es un test de diagnóstico genético alineado con las recomendaciones de los expertos a nivel internacional, y que analiza los 7 genes más frecuentemente asociados a la hipercolesterolemia familiar. Además, también evalúa otros aspectos importantes para orientar y adecuar el tratamiento de la hipercolesterolemia en cada paciente.",
          link: "https://www.genincode.com/es/lipid-incode-test-genetico-hipercolesterolemia/",
        },
        {
          img: "../assets/Assetsx1/OncoRisk.png",
          title: "OncoRisk",
          description:
            "OncoRisk® evalúa el riesgo genético a desarrollar cáncer hereditario de: mama, ovario, útero, colon y recto, melanoma, páncreas, gástrico y próstata. Conocer tu riesgo te permite enfocar estrategias de prevención y diagnóstico temprano.",
          link: "https://www.sohin.mx/",
        },
      ],
    };
  },
  methods: {
    goTo(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style>
.home-baner2 {
  height: 46vw;
  background-image: url("../assets/Assetsx1/Header002.png");
  background-repeat: no-repeat;
  background-size: 100% 46vw;
}
.home-baner-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  margin-left: 10%;
  text-align: justify;
  height: 100%;
}
.home-baner-cont2 p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 3.3vw;
}
.home-baner-cont-desc {
  margin-top: 7%;
}
.home-baner-cont-desc h2 {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 1.8vw;
}
.home-baner-cont-desc p {
  font-size: 1.3vw;
}
.portafolio {
  position: relative;
  padding: 5% 0 0;
}
.portafolio-img1 img,
.portafolio-img2 img,
.portafolio-img3 img,
.portafolio-img4 img {
  width: 100%;
}
.portafolio-img1 {
  position: absolute;
  z-index: -1;
  top: 2%;
  right: 0;
  width: 85%;
}
.portafolio-img2 {
  position: absolute;
  z-index: -1;
  top: 32%;
  right: 0;
  left: 0;
  margin: auto;
  width: 45%;
}
.portafolio-img3 {
  position: absolute;
  z-index: -1;
  top: 51%;
  right: 0;
  width: 85%;
}
.portafolio-img4 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
}
.portafolio-title {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 3vw;
  margin-left: 4%;
}
#carousel-diagnosis {
  background-color: #134270;
  border-radius: 0 20px 20px 0;
  padding: 7% 0 12% 7%;
}
#carousel-prognosis {
  background-color: #1cb7c7;
  border-radius: 20px 0 0 20px;
  padding: 7% 0 12% 7%;
}
#carousel-wellbeing {
  background-color: #c4d600;
  border-radius: 0 20px 20px 0;
  padding: 7% 0 12% 7%;
}
.portafolio-contslideL {
  display: grid;
  grid-template-columns: 30% 70%;
  margin: 10%;
  border-radius: 20px;
  box-shadow: 0px 3px 15px -3px #1f26875e;
}
.portafolio-contslideR {
  display: grid;
  grid-template-columns: 70% 30%;
  margin: 9% 10%;
  border-radius: 20px;
  box-shadow: 0px 3px 15px -3px #1f26875e;
}
.portafolio-titleimg {
  background-color: #fff;
  position: relative;
  z-index: 1;
  border-radius: 20px 0 0 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 15% 12%;
}
.portafolio-titleimgR {
  border-radius: 0 20px 20px 0;
  box-shadow: -10px 0px 10px 0px #1f26875e;
}
.portafolio-titleimg img {
  width: 100%;
  box-shadow: 0px 3px 15px -3px #1f26875e;
  margin-bottom: 12%;
}
.portafolio-titleimg p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 3.5vw;
}
.portafolio-item {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15%;
  border-radius: 20px;
  width: 24vw;
  height: 31vw;
}
.portafolio-item-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.portafolio-item-cont hr {
  width: 100%;
  border: #134270 solid 0.5px;
  margin-bottom: 7%;
}
.portafolio-item-cont-img{
  width: 75%;
  padding-top: 3%;
}
.portafolio-item-cont-img img {
  width: 100%;
}
.portafolio-item-cont-desc h1 {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 1.8vw;
  margin-bottom: 1%;
}
.portafolio-item-cont-desc p {
  color: #134270;
  font-family: "Mulish", sans-serif;
  font-size: 1vw;
  text-align: justify;
}
.portafolio-item-buttom {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15%;
}
.portafolio-item-buttom button {
  background-color: #1cb7c7;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1vw;
  padding: 1.7% 15%;
  cursor: pointer;
}
.owl-theme .owl-nav [class*="owl-"] {
  background-color: transparent!important;
}
.owl-theme .owl-nav [class*="owl-"]:hover {
  background-color: transparent!important;
}
.owl-nav {
  position: absolute;
  bottom: -17%;
  right: 5%;
  height: fit-content;
}
.flecha-izquierdaDiagnosis {
  color: #1cb7c7;
  /* background-color: #134270; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-derechaDiagnosis {
  color: #1cb7c7;
  /* background-color: #134270; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-izquierdaPrognosis {
  color: #134270;
  /* background-color: #134270; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-derechaPrognosis {
  color: #134270;
  /* background-color: #134270; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-izquierdaWellbeing {
  color: #134270;
  /* background-color: #134270; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.flecha-derechaWellbeing {
  color: #134270;
  /* background-color: #134270; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.5vw;
}
.footer-servicios {
  position: relative;
  height: 15vw;
}
.footer-logosS {
  bottom: 30%;
}

@media (max-width: 400px) {
  .home-baner2 {
    height: 100vw;
    background-image: url("../assets/Assetsx1/HeaderCompleto.png");
    background-size: 100% 45vw;
  }
  .home-baner-cont2 {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    text-align: justify;
    padding: 2% 0;
    margin-left: 4%;
  }
  .portafolio-img2{
    top: 31%;
  }
  .home-baner-cont2 p {
    font-size: 5.7vw;
  }
  .home-baner-cont-desc h2 {
    font-size: 3.7vw;
  }
  .home-baner-cont-desc p {
    font-size: 2.7vw;
    margin-bottom: 2%;
  }
  #carousel-diagnosis {
    border-radius: 0 0 20px 20px;
    padding: 5% 0 5% 5%;
  }
  #carousel-prognosis {
    grid-row: 2;
    border-radius: 0 0 20px 20px;
    padding: 5% 0 5% 5%;
  }
  #carousel-wellbeing {
    border-radius: 0 0 20px 20px;
    padding: 5% 0 5% 5%;
  }
  .portafolio-title {
    font-size: 5vw;
  }
  .portafolio-contslideL {
    grid-template-columns: 100%;
    margin: 5% 2%;
  }
  .portafolio-contslideR {
    grid-template-columns: 100%;
    margin: 15% 2%;
  }
  .portafolio-titleimg {
    position: relative;
    z-index: 0;
    display: grid;
    grid-template-columns: 30% 60%;
    border-radius: 20px 20px 0 0;
    justify-content: space-between;
    align-items: center;
    padding: 7% 5%;
    box-shadow: 0px 10px 10px 0px #1f26875e;
  }
  .portafolio-titleimg img {
    margin-bottom: 0%;
  }
  .portafolio-titleimg p {
    font-size: 4.5vw;
  }

  .portafolio-item {
    padding: 0 5%;
    width: 50vw;
    height: 85vw;
  }
  .portafolio-item-cont-desc h1 {
    font-size: 3.7vw;
    margin-bottom: 2%;
  }
  .portafolio-item-cont-desc p {
    font-size: 2.7vw;
  }
  .portafolio-item-buttom button {
    font-size: 2.7vw;
  }
  .owl-nav {
    top: -30%;
    z-index: 3;
  }
  .flecha-izquierdaDiagnosis,
  .flecha-derechaDiagnosis,
  .flecha-derechaPrognosis,
  .flecha-izquierdaPrognosis,
  .flecha-izquierdaWellbeing,
  .flecha-derechaWellbeing {
    font-size: 9vw;
  }

  .footer-servicios {
    position: relative;
    height: 55vw;
  }
  .footer-logosS {
    bottom: 15%;
  }
}
@media (max-width: 1810px) {
}
</style>